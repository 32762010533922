<template>
    <BT-Blade-Item
        bladeName="supply-pointer"
        navigation="supply-pointers"
        title="Supply Pointer"
        :bladesData="bladesData"
        :defaultBladeWidth="700"
        @fetched="pointerFetched"
        :onNew="x => { return createPointer(x) }">
        <template slot-scope="{ item, data }">
            <v-toolbar>
                <v-btn-toggle 
                    v-model="weekdays"
                    class="primary"
                    multiple
                    @change="changeWeekdays">
                    <v-btn
                        v-for="wDay in weekdayOptions"
                        :key="wDay"
                        :value="wDay"
                        :disabled="!data.isEditing && !data.isNew">{{ wDay }}</v-btn>
                </v-btn-toggle>
            </v-toolbar>
            
            <BT-Field-Select
                v-model="item.locationID"
                @change="updateLocation"
                navigation="locations"
                itemText="locationName"
                label="Supply Location"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Snack v-model="msg" />

            <GmapMap
                :center="center"
                :zoom="7"
                style="width: 100%; height: 60vh;"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false }">
                    
                    <gmap-marker :position="center"/>

                    <gmap-polygon 
                        :paths="item.boundary"
                        :draggable="data.isEditing || data.isNew"
                        @paths_changed="($event) => { updatePointerPaths($event, item) }"
                        :editable="data.isEditing || data.isNew"
                        :fillOpacity="0.5"
                        class="polygon" />
            </GmapMap>

            <v-divider />
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Supply-Pointer-Blade',
    data: function() {
        return {
            center: { lat: -38, lng: 144 },
            isAlways: false,
            isLoading: false,
            msg: null,
            pointer: null,
            weekdays: [],
            // weekdayOptions: ['Always', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        // templateID: {
        //     type: String,
        //     default: null
        // }
    },
    methods: {
        createPointer(bladeData) {
            var location = null;
            if (bladeData != null && bladeData.data != null && bladeData.data.location != null) {
                location = bladeData.data.location;
                this.center = { lat: location.lat, lng: location.lng };
            }

            return {
                id: null,
                weekdays: 'Always',
                isAlways: false,
                boundary: this.getDefaultArea(),
                locationID: location != null ? location.id : null, //bladeData.data.location.id,
                pointerType: 'GEO'
            };
        },
        getDefaultArea() {
            return [ 
                { lat: this.center.lat - 1, lng: this.center.lng + 1 },
                { lat: this.center.lat - 1, lng: this.center.lng - 1 },
                { lat: this.center.lat + 1, lng: this.center.lng - 1 },
                { lat: this.center.lat + 1, lng: this.center.lng + 1 }
            ];
        },
        changeWeekdays(val) {
            if (this.isAlways && val.length > 1) {
                val = val.filter(y => y != 'Always');
            }
            if (!this.isAlways && val.some(y => y == 'Always')) {
                val = ['Always'];
            }

            this.isAlways = val.some(y => y == 'Always');
            
            this.pointer.weekdays = val.toString();
            this.weekdays = val;
        },
        pointerFetched(pointer) {
            if (pointer != null) {
                if (pointer.location != null) {
                    this.center = { lat: pointer.location.lat, lng: pointer.location.lng };
                }
                if (pointer.weekdays != null) {
                    this.weekdays = pointer.weekdays.split(',');
                }
                else {
                    this.weekdays = [];
                }
                this.isAlways = this.weekdays.some(y => y == 'Always');
                this.pointer = pointer;
            }
        },
        async updateLocation(item) {
            try {
                var l = await this.$BlitzIt.store.get('locations', item);
                if (l != null) {
                    this.center = { lat: l.lat, lng: l.lng };
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
        },
        updatePointerPaths(mvcArray, pointer) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            pointer.boundary = paths[0];
        },
    }
}
</script>